import {
    BCard,
    BCardText,
    BCardTitle,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormDatepicker,
    BFormInput,
    BButton,
    BFormSelect 
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import ModalMostrarPdf from "../components/ModalMostrarPdf.vue";
import { formatearFecha } from "../utils";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";

export default {
    props: {
        titulo: {
            type: String,
            required: true,
        },
        servicioGetReporte: {
            type: Function,
            required: true,
        },
        servicioGetReportePdf: {
            type: Function,
            required: false,
            // required: true,
        },
        tipoTransaccion: {
            type: String,
             // required: true,
            required: false,
            validator: (value) => {
                return ["compra", "venta"].includes(value);
            }
        },
    },
    components: {
        BCard,
        BCardText,
        BCardTitle,
        BRow,
        BCol,
        BFormGroup,
        BForm,
        ValidationObserver,
        ValidationProvider,
        BButton,
        flatPickr,
        BFormSelect,
        ModalMostrarPdf,
        BFormInput
    },
    directives: {
        ripple: Ripple,
    },
    data() {
        return {
            flatPickrConfig: {
                locale: Spanish,
                maxDate: "today",
                mode: "range",
                dateFormat: "Y-m-d",
                altFormat: "Y-m-d",
                defaultDate: [formatearFecha(new Date()), formatearFecha(new Date())],
            },
            rangoFechas: [formatearFecha(new Date()), formatearFecha(new Date())],
            required,
            items: [],
            loading: false,
            pdf: "",
            pdfBase64: "",
            mostarModal: false,
        };
    },
    methods: {
        onChange(selectedDates, dateStr, instance) {
            if (selectedDates[0] && selectedDates[1]) {
                this.form.desde = instance.formatDate(selectedDates[0], "Y-m-d");
                this.form.hasta = instance.formatDate(selectedDates[1], "Y-m-d");
            }
        },
        onClose(selectedDates, dateStr, instance) {
            if (!selectedDates[0] || !selectedDates[1]) {
                this.form.desde = null;
                this.form.hasta = null;
            }
        },
        validar(enPdf) {
            this.$refs.form.validate().then((success) => {
                if (success) {
                    if (enPdf) {
                        this.mostrarPdf();
                    } else {
                        this.getReporte();
                    }
                }
            });
        },
        cerrarModal() {
            this.mostarModal = false;
            this.pdf = "";
            this.pdfBase64 = "";
        },
        async getReporte() {
            console.log(this.form);
            const parametros = {
                ...this.form,
                sedeId: this.mixing.sede_id,
            };

            this.loading = true;
            try {
                const response = await this.servicioGetReporte(parametros);

                this.items = response.data;

                const mensajeAdicional = this.items.length > 0 ? "" : "Sin registros";

                this.$bvToast.toast(`¡Consulta exitosa! ${mensajeAdicional}`, {
                    title: "ÉXITO!",
                    variant: "success",
                    solid: false,
                });
            } catch (error) {
                console.log(error);

                this.$bvToast.toast(error.response.data.message, {
                    title: "ERROR!",
                    variant: "danger",
                    solid: false,
                });
            } finally {
                this.loading = false;
            }
        },
        async mostrarPdf() {
            const parametros = {
                ...this.form,
                sedeId: this.mixing.sede_id,
            };

            try {
                const response = await this.servicioGetReportePdf(parametros);
                this.pdfBase64 = response.data.data;
                this.pdf = `data:application/pdf;base64,${response.data.data}#toolbar=0`;
                this.mostarModal = true;

                this.$bvToast.toast("¡Reporte generado exitosamente!", {
                    title: "ÉXITO!",
                    variant: "success",
                    solid: false,
                });
            } catch (error) {
                console.log(error);

                this.$bvToast.toast(error.response.data.message, {
                    title: "ERROR!",
                    variant: "danger",
                    solid: false,
                });
            }
        },
    },
};

