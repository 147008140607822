<template>
  <div>
    <b-card :title="titulo" :no-body="dashboard">
      <b-card-text>
        <b-form>
          <validation-observer ref="form">
            <b-row>
              <b-col cols="12" md="4" xl="2">
                <b-form-group
                  label="Estado"
                  label-for="estado"
                  label-cols-lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Estado"
                    rules="required"
                    vid="estado"
                  >
                    <b-form-select
                      v-model="form.estado"
                      :state="errors.length > 0 ? false : null"
                      :options="
                        tipo == 'catalogo'
                          ? estadoCatalogo
                          : tipo == 'existencia'
                          ? estadoExistencia
                          : estadoVencimeinto
                      "
                      size="sm"
                    />
                    <small class="text-danger text-small">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col cols="12" md="4" xl="2" v-if="form.estado == 'aVencer'">
                <b-form-group
                  label="Estado"
                  label-for="estado"
                  label-cols-lg="3"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Estado"
                    rules="required"
                    vid="estado"
                  >
                    <flat-pickr
                      v-model="form.anioMes"
                      class="form-control form-control-sm-small"
                      placeholder="SELECCIONE UN MES"
                      :config="configMensual"
                    />
                    <small class="text-danger text-small">{{
                      errors[0]
                    }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                cols="12"
                md="8"
                xl="8"
                class="mt-1 mt-md-0 pt-personalizado"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-danger"
                  size="sm"
                  @click="restablecer"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="RotateCwIcon" class="mr-50" />
                    <span class="align-middle">RESTABLECER</span>
                  </span>
                  <feather-icon
                    icon="RotateCwIcon"
                    class="d-md-none"
                    size="14"
                  />
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-primary"
                  size="sm"
                  class="ml-1"
                  @click="validar(false)"
                >
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  <span class="align-middle"
                    >{{ dashboard ? "CONSULTAR" : "CONSULTAR EN PANTALLA" }}
                  </span>
                </b-button>

                <b-button
                  v-if="!dashboard"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="outline-secondary"
                  size="sm"
                  class="ml-1"
                  @click="validar(true)"
                >
                  <span class="d-none d-md-block">
                    <feather-icon icon="FileIcon" class="mr-50" />
                    <span class="align-middle">VISUALIZAR EN PDF</span>
                  </span>
                  <feather-icon icon="FileIcon" class="d-md-none" size="14" />
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </b-form>
      </b-card-text>
      <b-card-text>
        <component
          :is="componenteListado"
          ref="listado"
          :items="items"
          :loading="loading"
        ></component>
      </b-card-text>
    </b-card>

    <ModalMostrarPdf
      :titulo="`${titulo} del ${this.form.desde} al ${this.form.hasta}`"
      :pdf="pdf"
      :pdfBase64="pdfBase64"
      :mostrarModal="mostarModal"
      @cerrarModal="cerrarModal"
    />
  </div>
</template>
    
<script>
import Ripple from "vue-ripple-directive";
import ReportesMixin from "../../mixins/ReportesMixin";
import ListadoCatalogo from "../views/ListadoCatalogo.vue";
import ListadoExistencia from "../views/ListadoExistencia.vue";
import ListadoVencimiento from "../views/ListadoVencimiento.vue";
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index.js";
import "flatpickr/dist/plugins/monthSelect/style.css";
import moment from "moment";

export default {
  name: "SedeHastaDesdeLayout",
  mixins: [ReportesMixin],
  props: {
    servicioGetReporte: {
      type: Function,
      required: true,
    },
    tipo: {
      type: String,
      required: true,
      validator: (value) => {
        return ["catalogo", "existencia", "vencimiento"].includes(value);
      },
    },
    dashboard: {
      type: Boolean,
      default: false,
      required: false,
    },
    componenteListado: {
      type: String,
      required: true,
      validator: (value) => {
        return [
          "ListadoCatalogo",
          "ListadoExistencia",
          "ListadoVencimiento",
        ].includes(value);
      },
    },
  },
  components: {
    ListadoCatalogo,
    ListadoExistencia,
    ListadoVencimiento,
    flatPickr,
  },
  directives: {
    ripple: Ripple,
  },
  data() {
    return {
      form: {
        estado: "todos",
        anioMes: "",
      },
      estadoCatalogo: [
        { value: "todos", text: "Todos" },
        { value: "activos", text: "Activos" },
        { value: "inactivos", text: "Inactivos" },
      ],
      estadoExistencia: [
        { value: "todos", text: "Todos" },
        { value: "conExistencia", text: "Con Existencia" },
        { value: "sinExistencia", text: "Sin Existencia" },
        { value: "existenciaMinima", text: "Existencia Minima" },
      ],
      estadoVencimeinto: [
        { value: "aVencer", text: "a Vencer" },
        { value: "vencidos", text: "Vencidos" },
      ],
      configMensual: {
        locale: Spanish,
        dateFormat: "Y-m",
        plugins: [
          new monthSelectPlugin({
            shorthand: true, //defaults to false
            dateFormat: "Y-m", //defaults to "F Y"
            altFormat: "Y-m", //defaults to "F Y"
          }),
        ],
      },
    };
  },
  methods: {
    restablecer() {
      if (this.tipo == "catalogo" || this.tipo == "existencia") {
        this.form.estado = "todos";
      } else {
        this.form.estado = "aVencer";
        const fechaActual = moment();
        this.form.anioMes = fechaActual.format("YYYY-MM");
      }
      this.items = [];
    },
  },
  mounted() {
    this.restablecer();
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/reportes.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style scoped lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.text-small {
  font-size: 11px;
}

@media (min-width: 1500px) {
  .text-small {
    font-size: 12px;
  }
}
</style>
    