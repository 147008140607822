import { AgGridVue } from "ag-grid-vue";
import {
    BCard,
    BCardText,
    BOverlay,
    BPagination,
    BRow,
    BCol,
} from "bootstrap-vue";

export default {
    components: {
        BCard,
        BCardText,
        AgGridVue,
        BOverlay,
        BPagination,
        BRow,
        BCol,
    },
    props: {
        items: {
            type: Array,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
        tipoTransaccion: {
            type: String,
            required: false,
            validator: (value) => {
                return ["compra", "venta"].includes(value);
            },
        },
    },
    data() {
        return {
            gridOptions: {
                suppressPropertyNamesCheck: true,
                localeText: {
                    noRowsToShow: "No hay filas para mostrar",
                    contains: "Contiene",
                    notContains: "No Contiene",
                    equals: "Es Igual",
                    notEqual: "No es Igual",
                    startsWith: "Comienza con",
                    endsWith: "Termina con",
                    filterOoo: "Filtrar...",
                },
            },
            rowClassRules: null,
            gridApi: null,
            filasSeleccionadas: [],
            defaultColDef: {
                //editable: false,
                sortable: false,
                filter: true,
                floatingFilter: true,
                resizable: true,
                suppressMenu: true,
            },
        }
    },
    computed: {
        paginationPageSize() {
            return this.gridApi ? this.gridApi.paginationGetPageSize() : 25;
        },
        totalPages() {
            return this.gridApi ? this.gridApi.paginationGetTotalPages() : 0;
        },
        currentPage: {
            get() {
                return this.gridApi ? this.gridApi.paginationGetCurrentPage() + 1 : 1;
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1);
            },
        },
    },
    methods: {
        onGridReady(params) {
            params.api.sizeColumnsToFit();
        },
        onFirstDataRendered(params) {
            params.api.sizeColumnsToFit();
        },
        calcularTotal(key) {
            let resultado = 0;
            for (let index = 0; index < this.items.length; index++) {
                resultado += parseFloat(this.items[index][key]);
            }
            return resultado.toFixed(2);
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
        this.gridColumnApi = this.gridOptions.columnApi;
    },
}