import axiosIns from "@/libs/axios";

const getReporteInventarioCompras = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/compras?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteInventarioVentas = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/ventas?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteInventarioMovimientoGeneral = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/movimiento-general?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteInventarioCatalogo = async ({ sedeId, estado }) => {
  return await axiosIns.get(`reporte-inventarios/catalogos?sede_id=${sedeId}&estado=${estado}`)
}

const getReporteInventarioExistencia = async ({ sedeId, estado }) => {
  return await axiosIns.get(`reporte-inventarios/existencias?sede_id=${sedeId}&estado=${estado}`)
}

const getReporteInventarioCategoria = async ({ sedeId, categoria_id }) => {
  return await axiosIns.get(`reporte-inventarios/categorias?sede_id=${sedeId}&categoria_id=${categoria_id}`)
}

const getReporteInventarioMarca = async ({ sedeId, marca_id }) => {
  return await axiosIns.get(`reporte-inventarios/lineas-marcas?sede_id=${sedeId}&marca_id=${marca_id}`)
}

const getReporteInventarioMovimientoGeneralUsuario = async ({ sedeId, usuarioId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/movimiento-general-usuario?sede_id=${sedeId}&usuario_id=${usuarioId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteInventarioKardex = async ({ sedeId, productoId}) => {
  return await axiosIns.get(`reporte-inventarios/kardex?sede_id=${sedeId}&producto_id=${productoId}`)
}

const getReporteInventarioKardexFechas = async ({ sedeId, productoId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/kardex-fechas?sede_id=${sedeId}&producto_id=${productoId}&desde=${desde}&hasta=${hasta}`)
}

const getReporteInventarioInventarioGeneral = async ({ sedeId}) => {
  return await axiosIns.get(`reporte-inventarios/invetario-general?sede_id=${sedeId}`)
}

const getReporteInventarioComprasPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/compras-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteInventarioVentasPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/ventas-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteInventarioExistenciaPdf = async ({ sedeId, estado }) => {
  return await axiosIns.get(`reporte-inventarios/existencias-pdf?sede_id=${sedeId}&estado=${estado}&tipo_respuesta=base64`)
}

const getReporteInventarioCatalogoPdf = async ({ sedeId, estado }) => {
  return await axiosIns.get(`reporte-inventarios/catalogos-pdf?sede_id=${sedeId}&estado=${estado}&tipo_respuesta=base64`)
}

const getReporteInventarioInventarioGeneralPdf = async ({ sedeId}) => {
  return await axiosIns.get(`reporte-inventarios/invetario-general-pdf?sede_id=${sedeId}&tipo_respuesta=base64`)
}

const getReporteInventarioMarcaPdf = async ({ sedeId, marca_id }) => {
  return await axiosIns.get(`reporte-inventarios/lineas-marcas-pdf?sede_id=${sedeId}&marca_id=${marca_id}&tipo_respuesta=base64`)
}

const getReporteInventarioCategoriaPdf = async ({ sedeId, categoria_id }) => {
  return await axiosIns.get(`reporte-inventarios/categorias-pdf?sede_id=${sedeId}&categoria_id=${categoria_id}&tipo_respuesta=base64`)
}

const getReporteInventarioMovimientoGeneralPdf = async ({ sedeId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/movimiento-general-pdf?sede_id=${sedeId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteInventarioMovimientoGeneralUsuarioPdf = async ({ sedeId, usuarioId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/movimiento-general-usuario-pdf?sede_id=${sedeId}&usuario_id=${usuarioId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteInventarioKardexPdf = async ({ sedeId, productoId}) => {
  return await axiosIns.get(`reporte-inventarios/kardex-pdf?sede_id=${sedeId}&producto_id=${productoId}&tipo_respuesta=base64`)
}

const getReporteInventarioKardexFechasPdf = async ({ sedeId, productoId, desde, hasta }) => {
  return await axiosIns.get(`reporte-inventarios/kardex-fechas-pdf?sede_id=${sedeId}&producto_id=${productoId}&desde=${desde}&hasta=${hasta}&tipo_respuesta=base64`)
}

const getReporteInventarioVencimiento = async ({ sedeId, estado, anioMes }) => {
  return await axiosIns.get(`reporte-inventarios/vencimientos?sede_id=${sedeId}&estado=${estado}&anioMes=${anioMes}`)
}

const getReporteInventarioVencimientoPdf = async ({ sedeId, estado, anioMes }) => {
  return await axiosIns.get(`reporte-inventarios/vencimientos-pdf?sede_id=${sedeId}&estado=${estado}&anioMes=${anioMes}&tipo_respuesta=base64`)
}

export default {
    getReporteInventarioCompras,
    getReporteInventarioVentas,
    getReporteInventarioMovimientoGeneral,
    getReporteInventarioCatalogo,
    getReporteInventarioExistencia,
    getReporteInventarioCategoria,
    getReporteInventarioMarca,
    
    getReporteInventarioMovimientoGeneralUsuario,
    getReporteInventarioKardex,
    getReporteInventarioKardexFechas,
    getReporteInventarioInventarioGeneral,

    getReporteInventarioVencimiento,

    getReporteInventarioComprasPdf,
    getReporteInventarioVentasPdf,
    getReporteInventarioExistenciaPdf,
    getReporteInventarioCatalogoPdf,
    getReporteInventarioInventarioGeneralPdf,
    getReporteInventarioMarcaPdf,
    getReporteInventarioCategoriaPdf,
    getReporteInventarioMovimientoGeneralPdf,
    getReporteInventarioMovimientoGeneralUsuarioPdf,
    getReporteInventarioKardexPdf,
    getReporteInventarioKardexFechasPdf,
    getReporteInventarioVencimientoPdf,
}