<template>
  <div>
    <b-overlay :show="loading">
      <ag-grid-vue
        :gridOptions="gridOptions"
        class="ag-theme-balham ag-grid-table mb-1"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        @grid-ready="onGridReady"
        :rowData="items"
        rowSelection="single"
        colResizeDefault="shift"
        :animateRows="false"
        :floatingFilter="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :rowClassRules="rowClassRules"
        @first-data-rendered="onFirstDataRendered"
        :alwaysShowVerticalScroll="true"
        :suppressPaginationPanel="true"
      >
      </ag-grid-vue>
    </b-overlay>
    <b-card-text>
      <b-row>
        <b-col cols="6" md="6">
          <span class="text-nowrap text-bold">
            <strong>
              Mostrando
              {{ currentPage * paginationPageSize - (paginationPageSize - 1) }}
              -
              {{
                items.length - currentPage * paginationPageSize > 0
                  ? currentPage * paginationPageSize
                  : items.length
              }}
              de {{ items.length }} Registros
            </strong>
          </span>
        </b-col>
        <b-col cols="6" md="6">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalPages"
            :per-page="1"
            align="right"
            size="sm"
            class="my-0"
          >
            <template #prev-text>
              <feather-icon size="18" icon="ChevronLeftIcon" />
            </template>
            <template #next-text>
              <feather-icon size="18" icon="ChevronRightIcon" />
            </template>
            <template #last-text>
              <feather-icon size="18" icon="ChevronsRightIcon" />
            </template>
            <template #first-text>
              <feather-icon size="18" icon="ChevronsLeftIcon" />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </b-card-text>
  </div>
</template>
  
<script>
import ListadoReportesMixin from "../../mixins/ListadoReportesMixin";
export default {
  name: "ListadoExistencia",
  mixins: [ListadoReportesMixin],
  data() {
    return {
      columnDefs: [
        {
          headerName: "CODIGO",
          field: "codigo",
          filter: true,
          minWidth: 200,
          maxWidth: 200,
        },
        {
          headerName: "LINEA-MARCA",
          field: "marca.descripcion",
          filter: true,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "CATEGORIA",
          field: "categoria.descripcion",
          filter: true,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "UNIDAD",
          field: "unidad.descripcion",
          filter: false,
          minWidth: 250,
          maxWidth: 250,
        },
        {
          headerName: "DESCRIPCION",
          field: "descripcion",
          filter: true,
          minWidth: 200,
        },
        {
          headerName: "CANTIDAD MINIMA",
          field: "existencia_minima",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
        {
          headerName: "CANTIDAD",
          field: "stock",
          type: "numericColumn",
          filter: false,
          minWidth: 150,
          maxWidth: 150,
        },
      ],
    };
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/components/agGridStyle.scss";
</style>
  