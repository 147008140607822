<template>
  <b-modal
    id="modal-pdf"
    ref="modal-pdf"
    :title="titulo"
    no-close-on-backdrop
    no-close-on-esc
    size="xl"
    scrollable
    body-bg-variant="dark"
    body-text-variant="light"
    button-size="sm"
    ok-only
    hide-footer
    v-model="mostrar"
    @close="cerrarModal"
  >
    <vue-pdf-app
      v-show="pdf"
      :page-number="1"
      class="height-pdf"
      :pdf="pdf"
      :file-name="titulo"
      :config="config"
    >
      <template #toolbar-right-prepend="{}">
        <button
          @click="printPdf"
          type="button"
          title="Imprimir"
          style="padding: 8px 6px 0; font-size: 12px"
          class="prepend print hiddenMediumView vue-pdf-app-icon print-button"
        ></button>
      </template>

      <template #secondary-toolbar-prepend="{}">
        <button
          title="Imprimir"
          type="button"
          class="secondaryToolbarButton print vue-pdf-app-icon print-button"
          @click="printPdf"
        >
          <span>Imprimir</span>
        </button>
      </template>
    </vue-pdf-app>
  </b-modal>
</template>

<script>
import { BModal, VBModal } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "vue-pdf-app/dist/icons/main.css";
import printJS from "print-js";

export default {
  components: {
    BModal,
  },
  props: {
    titulo: {
      type: String,
      default: "Mostrar Documento",
    },
    pdf: {
      type: String,
      default: "",
    },
    mostrarModal: {
      type: Boolean,
      default: false,
    },
    pdfBase64: {
      type: String,
      default: "",
    },
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      config: {
        sidebar: false,
        toolbar: {
          toolbarViewerLeft: {
            findbar: false,
          },
          toolbarViewerRight: {
            presentationMode: false,
            openFile: false,
            print: false,
            viewBookmark: false,
          },
        },
        secondaryToolbar: {
          secondaryPresentationMode: false,
          secondaryOpenFile: false,
          secondaryPrint: false,
          secondaryViewBookmark: false,
          firstPage: false,
          lastPage: false,
          pageRotateCw: false,
          pageRotateCcw: false,
          cursorSelectTool: false,
          cursorHandTool: false,
          scrollVertical: false,
          scrollHorizontal: false,
          scrollWrapped: false,
          spreadNone: false,
          spreadOdd: false,
          spreadEven: false,
        },
      },
      mostrar: false,
    };
  },
  watch: {
    mostrarModal: function (value) {
      if (value) {
        this.mostrar = true;
      }
    },
  },
  methods: {
    cerrarModal() {
      this.$emit("cerrarModal");
    },
    printPdf() {
      printJS({
        printable: this.pdfBase64,
        type: "pdf",
        base64: true,
      });
    },
  },
};
</script>
  
<style lang="scss">
.height-pdf {
  height: calc(var(--vh, 1vh) * 100 - 12rem);
  .pdfViewer .page > * {
    height: 100% !important;
  }
}
#modal-pdf {
  .modal-xl {
    margin-left: auto;
    margin-right: auto;
  }
  .modal-body {
    padding: 0.5rem 0.5rem;
  }
}

@media (min-width: 800px) {
  #modal-pdf {
    .modal-xl {
      margin-left: 20%;
      margin-right: 20%;
    }
  }
}
</style>
